header {
	padding-top: 40px;
	background-color: rgba(64,81,73,0.17);
	.three-col  {
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.title-container {
		text-align: center;
		.big-text {
			margin-bottom: 10px;
		}
		.green-text {
			color: #2CB026;
			font-size: 2.4rem;
			font-weight: 700;
			letter-spacing: 3px;
		}
	}
	.logo-container {
		margin-top: -15px;
		z-index: 100;
		a {
		    position: relative;
			display: block;
			max-width: 271px;
			margin: 0 auto;
			img {
		    	display: block;
		    	margin: 0 auto;
		    	width: 67.5%;
		    }
		    h1 {
		    	font-size: 1.5rem;
		    	color: black;
		    	font-style: italic;
		    	font-weight: 700;
				text-align: center;
				letter-spacing: 3px;
		    }
	    	&:hover {
	    		text-decoration: none;
	    	}
	    	&:before {
	    		content: "";
	    		position: absolute;
	    		max-height: 102px;
	    		height: 100%;
	    		width: 100%;
	    		top: 15px;
	    		border-style: solid;
				border-color: #2CB026;
			    border-radius: 50%;
			    border-width: 2px 0px 1px;
	    	}
		}
	}
	.phone-container {
		a {
		    margin-right: 0;
		    margin-left: auto;
		    display: block;
			font-size: 1.5rem;
			color: black;
			max-width: fit-content;
			text-align: center;
			span {
				display: block;
			}
			.under-text {
				font-size: .7rem;
				font-style: italic;
			}
			&:hover {
				text-decoration: none;
			}
		}
	}
	.nav-container {
		margin-top: 10px;
		ul {
			padding: 0;
		}
		li {
			list-style: none;
			padding: 0 40px;
			a {
				color: #2C5A26;
				position: relative;
				&:hover {
					text-decoration: none;
				}
			}
			&.active {
				a:before {
					content: "";
					position: absolute;
					width: 100%;
					height: 100%;
					border-bottom: 1px solid #2C5A26;
					bottom: -1px;
				}
			}
		}
	}
	@media (max-width: 1199.98px) {
		.three-col  {
			flex: 0 0 50%;
			max-width: 50%;
		}
		.title-container .big-text {
			margin-bottom: 0;
		}
		.phone-container {
		    margin-left: 50%;
		    margin-top: -70px;
		    a {
		    	margin-right: auto;
		    }
		}
	}
	@media (max-width: 767.98px) {
		.title-container .green-text {
			font-size: 2rem;
		}
		.phone-container {
			margin-top: -60px;
			a {
				font-size: 1.2rem;
			}
		}
		.nav-container li {
			padding: 0 20px;
		}
	}
	@media (max-width: 575.98px) {
		.three-col  {
			flex: 0 0 100%;
			max-width: 100%;
		}
		.logo-container {
			flex: 0 0 70%;
			max-width: 70%;
			a {
				max-width: 150px;
				h1 {
					font-size: 1rem;
				}
				&:before {
				    max-height: 56px;
				    top: 8px;
				}
			}
		}
		.title-container {
			.big-text {
				font-size: 1.1rem;
			}
			.green-text {
				font-size: 1.8rem;
			}
		}
		.burger-container {
			flex: 0 0 30%;
			max-width: 30%;
		}
		.phone-container {
		    margin-left: 0;
		    margin-top: -15px;
		    a {
		    	margin-right: auto;
		    }
		}
		.nav-container li {
			padding: 10px;
			text-align: center;
		}
		.navigation {
			opacity: 0;
			position: fixed;
			top: -100%;
			left: 0;
			width: 100vw;
			max-width: 100%;
			min-height: 100vh;
			height: 100%;
			padding-top: 140px;
			margin: 0;
			display: block;
			background: #dfe2e0;
			transition: all .5s ease;
			flex: 0 0 100%;
			z-index: 99;
			&.open{
	          opacity: 1;
	          top: 0;
	          overflow: auto;
	        }
		}
		.burger{
			z-index: 100;
			max-width: 37px;
			width: 100%;
			height: 30px;
			margin: 15px 0;
			position: relative;
			transform: rotate(0deg);
			transition: .5s ease-in-out;
			cursor: pointer;
			span{
				display: block;
				position: absolute;
				height: 6px;
				width: 100%;
				background-color: #000;
				border-radius: 6px;
				opacity: 1;
				left: 0;
				transform: rotate(0deg);
				transition: .25s ease-in-out;
				&:nth-child(1) {
					top: 0;
					transform-origin: left center;
				}
				&:nth-child(2) {
					top: 12px;
					transform-origin: left center;
				}
				&:nth-child(3) {
					top: 24px;
					transform-origin: left center;
				}
			}

			&.open {
				span:nth-child(1) {
					transform: rotate(45deg);
					top: -3px;
					left: 8px;
				}
				span:nth-child(2) {
					width: 0%;
					opacity: 0;
				}
				span:nth-child(3) {
					transform: rotate(-45deg);
					top: 24px;
					left: 8px;
				}
			}
		}
	}
	@media (max-width: 575.98px) {
		padding-bottom: 20px;
	}
}