@font-face {
    font-family: ubuntu;
    font-style: normal;
    font-weight: 400;
    font-path: "/css/fonts/ubuntu/UbuntuRegular/UbuntuRegular";
}

@font-face {
    font-family: ubuntu;
    font-style: italic;
    font-weight: 400;
    font-path: "/css/fonts/ubuntu/UbuntuItalic/UbuntuItalic";
}

@font-face {
    font-family: ubuntu;
    font-style: normal;
    font-weight: 700;
    font-path: "/css/fonts/ubuntu/UbuntuBold/UbuntuBold";
}

@font-face {
    font-family: ubuntu;
    font-style: normal;
    font-weight: 300;
    font-path: "/css/fonts/ubuntu/UbuntuLight/UbuntuLight";
}

@font-face {
    font-family: ubuntu;
    font-style: italic;
    font-weight: 300;
    font-path: "/css/fonts/ubuntu/UbuntuLightItalic/UbuntuLightItalic";
}

@font-face {
    font-family: ubuntu;
    font-style: normal;
    font-weight: 600;
    font-path: "/css/fonts/ubuntu/UbuntuMedium/UbuntuMedium";
}

@font-face {
    font-family: ubuntu;
    font-style: italic;
    font-weight: 600;
    font-path: "/css/fonts/ubuntu/UbuntuMediumItalic/UbuntuMediumItalic";
}

@font-face {
    font-family: ubuntu;
    font-style: italic;
    font-weight: 700;
    font-path: "/css/fonts/ubuntu/UbuntuBoldItalic/UbuntuBoldItalic";
}