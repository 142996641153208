.our-works-block {
	padding: 10px 0 20px;
	h2 {
		font-size: 1.5rem;
		text-decoration: underline;
		text-align: center;
		font-weight: 700;
	}
	.image-container {
		color: black;
		&:hover {
			text-decoration: none;
		}
		img {
			max-width: 50%;
		    width: 100%;
		    max-height: 50%;
		    height: 100%;
		}
		p {
			text-align: center;
		}
		@media (max-width: 575.98px) {
			max-width: 350px;
    		margin: 0 auto;
		}
	}
}