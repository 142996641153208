.about-company-block {
	padding: 25px 0;
	p, h2, h3 {
		text-align: center;
		width: 100%;
		margin-bottom: 5px;
	}
	h2 {
		font-size: 1.5rem;
		text-decoration: underline;
		font-weight: 700;
		margin-bottom: 30px;
	}
	h3 {
		font-size: 1.8rem;
		font-weight: 700;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			width: 150px;
			height: 100%;
			bottom: -6px;
			left: calc(50% - 75px);
			border-bottom: 1px solid black;
		}
	}
	.underline-text {
		font-weight: 700;
		text-decoration: underline;
		margin-bottom: 20px;
	}
	.green-blocks-container {
		width: 100%;
		padding: 40px 0 20px;
		position: relative;
		.green-blocks {
			max-width: 900px;
			margin: 0 auto;
		}
		.two-block {
			padding-top: 40px;
		}
		.green-item {
			flex: 0 0 33.3%;
			max-width: 33.3%;
			position: relative;
			padding: 0 5px;
			.img-container {
				max-width: 120px;
				max-height: 120px;
				margin: 0 auto 30px;
			}
			span {
				position: absolute;
				width: 30px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				background-color: #FFCB50;
				border-radius: 50%;
				font-size: 1.1rem;
				font-weight: 700;
			}
			img {
				width: 100%;
				height: 100%;
			}
			p {
				font-size: 1rem;
			}
		}
		&:before {
			content: "";
			position: absolute;
			width: 70%;
		    height: 140px;
		    right: 5%;
			top: 100px;
		    border: 0 solid #F2F2F2;
		    border-width: 4px 4px 0 0;
		    border-radius: 0 400px 0;
		}
		&:after {
			content: "";
			position: absolute;
			width: 60%;
		    height: 140px;
		    right: 5%;
			top: 240px;
		    border: 0 solid #F2F2F2;
		    border-width: 0 4px 4px 0;
		    border-radius: 0 0 400px;
		    z-index: -1;
		}
	}
	.two-block-container {
		width: 100%;
		.two-block-item {
			flex: 0 0 50%;
			max-width: 50%;
			padding: 20px 10px;
			img {
				display: block;
				max-width: 392px;
				width: 100%;
				margin: 0 auto;
			}
		}
	}
	@media (max-width: 575.98px) {
		.green-blocks-container {
			.green-item {
				margin: 0 auto;
				flex: 0 0 100%;
    			max-width: 100%;
    			padding: 0 20px 20px;
    			.img-container {
    				margin-bottom: 10px;
					max-width: 80px;
					max-height: 80px;
    			}
			}
			.two-block {
				padding-top: 0;
			}
			&:before {
			    display: none;
			}
			&:after {
			    display: none;
			}
		}
	}
	@media (max-width: 767.98px) {
		.green-blocks-container {
			&:before {
			    height: 155px;
			}
			&:after {
			    height: 155px;
			    top: 255px;
			}
		}
	}
	@media (max-width: 991.98px) {
		.green-blocks-container {
			&:before {
			    height: 130px;
			}
			&:after {
			    height: 130px;
			    top: 230px;
			}
		}
	}
	@media (max-width: 1199.98px) {
		.green-blocks-container {
			&:before {
				width: 80%;
			    height: 140px;
			    right: 0;
			}
			&:after {
			    height: 140px;
			    right: 0;
			}
		}
	}
}