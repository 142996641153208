.pricing-cta {
  .bg-image {
    position: absolute;
    left: 0;
    bottom: 0;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .caption {
    padding-left: 15em;
    padding-top: 1.75em;

    @media screen and (max-width: 992px) {
      padding-left: 0;
      text-align: center;
    }

    h3 {
      font-weight: 700;
      margin-bottom: 0.75em;
    }

    p {
      font-size: 24px;
      margin-bottom: 0;
    }

    a {
      font-size: 30px;
      color: #2CB026;
      font-weight: 700;
    }
  }

  form {
    padding-top: 1.65em;
    padding-bottom: 1.65em;
    h3 {
      font-size: 24px;
      text-align: center;
      margin-bottom: 0.95em;
    }

    .form-group {
      margin-bottom: 0.75em;
    }

    label {
      display: block;
      text-align: right;
      margin-bottom: 0.15em;
    }

    input, textarea {
      margin-left: auto;
      margin-right: 0;
      display: block;
      border: 2px solid #7F7F7F;
      border-radius: 0;
      padding-left: 0.2em;
      padding-right: 0.2em;
      color: #4F4F4F;
      font-size: 14px;
      font-style: italic;

      &:hover {
        border-color: #B8BABC;
      }
    }

    input {
      height: 33px;
    }

    textarea {
      width: 100%;
      resize: none;
    }

    .btn-success {
      margin-left: auto;
      margin-right: 0;
      font-size: 18px;
      display: block;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      border-radius: 2em;
      border: none;
      padding-left: 1.8em;
      padding-right: 1.8em;
      background-color: #2CB026;

      &:hover {
        color: #2E2E2D;
      }
    }
  }
}