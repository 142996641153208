.pricing-head {
  min-height: 248px;
  background: transparent url("../img/pics/1 фон под шапкой.jpg?crc=3977732154") no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  color: #ffffff;
  padding-top: calc(1em + 1vw);

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
  }

  h2 {
    font-size: 48px;
    margin-bottom: 0;

    @media screen and (max-width: 992px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 18px;
  }

  hr {
    border-color: #fff;
    margin-top: 0.35em;
    margin-bottom: 0.35em;
  }
}