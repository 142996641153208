footer {
	background-color: rgba(64,81,73,0.17);
	padding: 40px 0 30px;
	&.bg-gray {
		background-color: #fff;
		border-top: 1px solid black;
	}
	a:hover {
		text-decoration: none;
	}
	.logo-container {
		flex: 0 0 25%;
		max-width: 25%;
		margin-top: -15px;
		margin-bottom: 20px;
		.img-container {
			position: relative;
			max-width: 271px;
			width: 100%;
			margin: 0 auto;
			img {
				display: block;
				margin: 0 auto;
				width: 67.5%;
			}
			&:before {
				content: "";
				position: absolute;
				max-height: 102px;
				height: 100%;
				width: 100%;
				top: 15px;
				border-style: solid;
				border-color: #2CB026;
			    border-radius: 50%;
			    border-width: 2px 0px 1px;
			}
		}
	}
	.nav-container {
		flex: 0 0 20%;
		max-width: 20%;
		ul {
			padding-left: 5px;
			list-style: none;
		}
		li {
			&:before {
				content: "— ";
			}
			a {
				color: black;
				font-size: .7rem;
				font-weight: 700;
			}
		}
	}
	.phone-container {
		flex: 0 0 20%;
		max-width: 20%;
		span {
			display: block;
		}
		a {
			color: black;
		}
		.under-text {
			font-size: .7rem;
			font-style: italic;
		}
	}
	.feedback-container {
		flex: 0 0 35%;
		max-width: 35%;
		padding: 0 20px;
		p {
			font-size: 1.2rem;
			text-align: right;
			margin-bottom: 10px;
		}
		a {
			display: block;
			max-width: 350px;
			margin: 0 0 0 auto;
			padding: 8px;
		    background-color: #2CB026;
		    border-radius: 20px;
		    text-align: center;
		    font-size: 1.25rem;
		    color: #FFFFFF;
		    line-height: 30px;
		    font-weight: 700;
		    transition: .2s ease;
		    &:hover {
		    	color: #7F7F7F;
		    }
		}
	}
	.politic-container {
		flex: 0 0 100%;
		max-width: 100%;
		a {
			display: block;
			width: 100%;
			text-align: center;
			color: black;
			font-size: .65rem;
			text-decoration: underline;
		}
	}
	@media (max-width: 575.98px) {
		.feedback-container {
			flex: 0 0 100%;
			max-width: 100%;
			margin-bottom: 20px;
			a {
				max-width: 280px;
			}
		}
		.phone-container {
			flex: 0 0 100%;
			max-width: 100%;
			text-align: center;
			margin-bottom: 20px;
		}
		.logo-container {
			flex: 0 0 60%;
			max-width: 60%;
			margin-top: 0px;
			padding: 0 10px;
			.img-container {
				max-width: 150px;
				&:before {
				    max-height: 56px;
				    top: 8px;
				}
			}
		}
		.nav-container {
			flex: 0 0 40%;
			max-width: 40%;
		}
	}
	@media (max-width: 991.98px) {
		.logo-container {
			margin-top: -10px;
			.img-container {
				max-width: 200px;
				&:before {
				    max-height: 75px;
				    top: 11px;
				}
			}
		}
	}
	@media (max-width: 1199.98px) {
		.feedback-container {
			flex: 0 0 60%;
			max-width: 60%;
			margin-bottom: 30px;
			p {
				text-align: center;
			}
			a {
				max-width: 300px;
				margin: 0 auto;
				padding: 5px;
			    font-size: 1rem;
			}
		}
		.phone-container {
			flex: 0 0 40%;
			max-width: 40%;
		}
		.logo-container {
			flex: 0 0 60%;
			max-width: 60%;
		}
		.nav-container {
			flex: 0 0 40%;
			max-width: 40%;
		}
	}	
	@media (max-width: 575.98px) {
		padding-top: 30px;
	}
}