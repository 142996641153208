.pricing-block {
  padding-top: 0.5em;
  .caption {
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 0.25em;

    h2 {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 0.5em;

      @media screen and (max-width: 992px) {
        font-size: 24px;
      }
    }

    p {
      font-size: 14px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 670px;
      margin-top: 0.35em;
      line-height: 1.25;
    }

    hr {
      max-width: 220px;
      border-bottom: 2px solid #000;
      margin-top: 0.5em;
      margin-bottom: 0.85em;
    }
  }

  .table-wrapper {
    overflow-x: auto;

    .table-header {
      color: #FFFFFF;
      font-size: 24px;
      background-color: rgba(44,176,38,0.8);
      font-weight: 700;
      padding-top: 16px;
      padding-left: 47px;
      padding-right: 23px;
      padding-bottom: 16px;
      min-width: 500px;

      @media screen and (max-width: 992px) {
        padding-left: 10px;
        font-size: 18px;
      }

      p {
        margin-bottom: 0;
      }
    }

    table {
      width: 100%;
      border: 1px solid #7F7F7F;
      min-width: 500px;

      th {
        background-color: rgba(127,127,127,0.29);
        border-bottom: 1px solid #7F7F7F;
        padding-top: 14px;
        padding-bottom: 14px;
        font-size: 24px;

        @media screen and (max-width: 992px) {
          font-size: 18px;
        }

        &:first-child {
          padding-left: 47px;

          @media screen and (max-width: 992px) {
            padding-left: 10px;
          }
        }
      }

      td {
        padding-top: 14px;
        padding-bottom: 14px;

        &:first-child {
          padding-left: 47px;

          @media screen and (max-width: 992px) {
            padding-left: 10px;
          }
        }

        & + td {
          width: 15%;
          min-width: 207px;
          padding-left: 3vw;
        }
      }

      &.bordered {

        td {
          padding-top: 18px;
          padding-bottom: 18px;
        }

        td + td {
          border-left: 1px solid #7F7F7F;
          padding-left: 6vw;
        }
      }

      tr+tr {
        border-top: 1px solid #7F7F7F;
      }

      & + table {
        border-top: none;
      }
    }
  }
}