.modal-container {
	.close {
		position: absolute;
	    top: -34px;
		right: -30px;
		@media (max-width: 575.98px) {
			right: 0;
		}
	}
	background-color: rgba(0, 0, 0, 0.6);
	&#modal-request-block {
		.modal-body {
			padding: 8px;
			position: relative;
		}
		h3 {
			font-size: 1.2rem;
			font-weight: 700;
			text-align: center;
			color: #3F3C4F;
			padding-bottom: 30px;
		}
		p {
			text-align: center;
			color: #3F3C4F;
			font-size: .8rem;
		}
		.form-container {
		    padding: 30px 0 50px;
			border: 2px solid #2C5A26;
			border-radius: 6px;
		}
		.form-item {
			padding: 5px 15px;
			position: relative;
			max-width: 405px;
			margin: 0 auto;
			&:before {
				content: "";
				position: absolute;
				left: 30px;
				top: 50%;
				transform: translateY(-50%);
			}
			&.form-name {
				&:before {
					width: 16px;
					height: 12px;
					background-image: url(../img/pics/form-icons-1.png);
				}
			}
			&.form-email {
				&:before {
					width: 14px;
					height: 15px;
					background-image: url(../img/pics/form-icons-2.png);
				}
			}
			&.form-tel {
				margin-bottom: 10px;
				&:before {
					width: 12px;
					height: 16px;
					background-image: url(../img/pics/form-icons-3.png);
				}
			}
		}
		input {
			border: 1px solid #53B8FF;
			border-radius: 20px;
			width: 100%;
			display: block;
			outline: none;
			font-size: .7rem;
			padding: 10px 10px 10px 50px;
		}
		.button-send {
			border-radius: 20px;
			max-width: 375px;
			width: 100%;
			margin: 0 auto;
			display: block;
			outline: none;
			font-size: .8rem;
			color: #fff;
			background-color: #2C5A26;
			transition: .3s ease;
			border: 2px solid #2C5A26;
			padding: 8px;
			font-weight: 700;
			&:hover {
				background-color: #fff;
				border: 2px solid #53B8FF;
				color: #53B8FF;
			}
		}
	}
	&#modal-confidence-block {
		.modal-dialog {
			min-width: 800px;
			@media (max-width: 575.98px) {
				h3 {
					font-size: 1rem;
				}
				.text-container {
					padding: 20px 20px 10px;
				}
				p {
					font-size: .7rem;
				}
			}
			@media (max-width: 991.98px) {
				min-width: unset;
			}
		}
		.modal-body {
			padding: 8px;
		}
		h3 {
			font-size: 1.5rem;
			color: #000;
			padding-bottom: 20px;
		}
		.text-container {
			padding: 40px 40px 20px;
		}
	}
}