*{box-sizing:border-box}

body{
    font-family: 'Roboto', serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.37;
}

.big-text {
	font-size: 1.2rem;
}

*:hover, *:focus {
	outline: none;
}

form p.formStatus {
    display: none;
}

form p.formStatus.error,
.modal-container#modal-request-block p.formStatus.error {
    color: red;
}

form p.formStatus.pending,
.modal-container#modal-request-block p.formStatus.pending {
    color: grey;
}

form p.formStatus.success,
.modal-container#modal-request-block p.formStatus.success {
    color: green;
}