.index-screen-img-block {
	background: transparent url(../img/pics/first-screen.jpg) no-repeat center top;
    background-size: cover;
    height: calc(0.391 * 100vw);
    @media (max-width: 991.98px) {
    	height: calc(0.391 * 100vw * 3/2);
	}
	@media (max-width: 575.98px) {
		height: calc(0.391 * 100vw * 2.5);
	}
}