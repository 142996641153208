.contacts-block {
  padding-top: 0.45em;
  padding-bottom: calc(1.5em + 2.75vw);

  h2 {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    margin-bottom: 2.5em;

    @media screen and (max-width: 992px) {
      margin-bottom: 1em;
    }

    &::after {
      position: absolute;
      width: 100%;
      background-color: #000000;
      height: 1px;
      left: 0;
      bottom: -0.32em;
      content: '';
      display: block;
    }
  }


  .input-username {

    input {
      background-image: url("../img/pics/name.png");
    }

  }

  .input-phone {

    input {
      background-image: url("../img/pics/phone.png");
    }
  }

  .input-text {
    textarea {
      background-image: url("../img/pics/text.png");
    }
  }

  .caption {
    h4 {
      margin-bottom: 1em;
    }

    .icon-block-phone,
    .icon-block-email {
      margin-top: 1.5em;
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 0.7em;

      p {
        margin-bottom: 0;
        margin-left: -0.1em;
      }
    }

    .phone {
      color: #7F7F7F;
      font-size: 30px;
      letter-spacing: 1px;
      pointer-events: none;
      display: block;
      margin-bottom: 0.5em;
    }

    .email {
      color: #7F7F7F;
      font-size: 30px;
      letter-spacing: 1px;
      pointer-events: none;
    }
  }

  form {
    @media screen and (max-width: 992px) {
      padding-top: 2em;
    }

    h3 {
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 0.65em;
    }
  }

  .form-group {
    position: relative;

    input, textarea {
      border-style: solid;
      border-width: 2px;
      border-color: #C4C4C4;
      border-radius: 3px;
      color: #7F7F7F;

      font-size: 16px;
      font-weight: 300;
      padding: 6px 6px 6px 36px;

      background-color: #F2F2F2;
      background-repeat: no-repeat;
      
      &:hover,
      &:focus,
      &.not-empty {
        border-color: #6B6B6B;
        background-color: #ffffff;
      }

      &:focus + label,
      &.not-empty + label{
        visibility: hidden;
      }
    }

    label {
      width: 298px;
      height: 34px;
      color: #7F7F7F;
      font-size: 16px;
      font-weight: 300;
      position: absolute;
      padding: 6px 6px 6px 36px;
      left: 0;
      top: 0.35em;
    }

    input {
      max-width: 340px;
      width: 100%;
      height: 46px;
      background-position: left center;
    }

    textarea {
      background-position: left top;
      resize: none;
      min-height: 85px;
      width: 100%;
    }
  }

  .btn-success {
    max-width: 340px;
    width: 100%;
    height: 37px;
    background-color: #2CB026;
    border-radius: 44px;
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
    display: block;
  }
}