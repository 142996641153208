.gallery-block {
	padding: 10px 0 20px;
	h2 {
		font-size: 1.5rem;
		text-decoration: underline;
		text-align: center;
		font-weight: 700;
	}
	p {
		font-weight: 700;
		text-align: center;
	}
	.fancybox {
		padding: 5px;
		@media (max-width: 575.98px) {
			padding: 10px 15px;
			max-width: 350px;
    		margin: 0 auto;
		}
	}
	img {
		width: 100%;
	}
}