html {
    font-family: ubuntu, sans-serif;
    font-size: 20px;
}

body {
    font-family: inherit;
    font-size: 0.9rem;
    line-height: 1.37;
}

@media (max-width: 991.98px) {
	html {
	    font-size: 16px;
	}
}